<template>
	<div id="contactUs">
		<section id="heroContent">
			<div id="heroText">
				<h1 class="main1">Questions?</h1>
				<h1 class="main2">Contact Us</h1>
				<h3 class="heroHeadline">
					Give us an overview of your needs and we'll get back to you.
				</h3>
			</div>
			<div id="heroImgCont">
				<img src="@/assets/iPhoneHold.svg" id="heroImg" />
			</div>
		</section>
		<section id="contactForm">
			<ContactForm />
		</section>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';
import { smoothScrollTop } from '@/plugins/utilities';

// Component Imports
import ContactForm from '@/components/contactForm/ContactForm.vue';

export default {
	name: 'Home',
	components: {
		ContactForm,
	},

	setup() {
		// Injections

		// Setup Returns
		return {
			smoothScrollTop,
		};
	},
};
</script>

<style scoped>
#contactUs {
	border: solid yellow 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	/* z-index: 10; */
}

#heroContent {
	height: auto;
	width: 100%;
	margin: 0px;
	position: relative;
	z-index: 0;
	border: solid green 0px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#heroText {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	border: solid green 0px;
	padding: 0px 0px 0px 40px;
	margin: 30px 0px 30px 0px;
	border: solid blue 0px;
	max-width: 510px;
	width: 100%;
}

#contactForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: solid yellow 0px;
	width: 100%;
	height: 100%;
	padding: 40px;
	margin: 40px 0px 0px 0px;
}

p {
	text-align: left;
	font-size: 18px;
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: -10px 0px -10px 0px;
	border: solid yellow 0px;
}

h1.main1 {
	font-size: clamp(10px, 11vw, 60px);
}

h1.main2 {
	font-size: clamp(20px, 16vw, 90px);
}

h3.heroHeadline {
	font-size: clamp(8px, 6vw, 24px);
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 18px;
	border: solid yellow 0px;
	width: 90%;
	max-width: 500px;
	text-align: left;
	font-weight: 500;
	margin: 10px 0px 0px 0px;
}

#heroImg {
	width: 100vw;
	max-width: 570px;
	height: auto;
	margin: 0px -30px 0px -30px;
	border: solid blue 0px;
	animation: heroImageBounce 10000ms ease-in-out infinite;
}

#heroImgCont {
	border: solid yellow 0px;
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
}
</style>
