<template>
	<div id="contactContainer">
		<form id="formCont" @submit.prevent="submitMessage()" v-if="!formAccepted">
			<h1 class="text-gradient">Please fill out the form below:</h1>
			<div class="small-field">
				<div class="input-container">
					<label for="firstname">First name*</label>
					<input
						type="text"
						placeholder="John"
						:class="{ 'not-valid': !firstname.init && !firstname.isValid }"
						v-model="firstname.val"
						@change="validateInput(firstname)"
					/>
				</div>
				<div class="input-container">
					<label for="lastname">Last name*</label>
					<input
						type="text"
						placeholder="Doe"
						:class="{ 'not-valid': !lastname.init && !lastname.isValid }"
						v-model="lastname.val"
						@change="validateInput(lastname)"
					/>
				</div>
				<div class="input-container">
					<label for="company">Company</label>
					<input
						type="text"
						placeholder="John Doe Services LLC"
						:class="{ 'not-valid': !company.init && !company.isValid }"
						v-model="company.val"
						@change="validateInput(company)"
					/>
				</div>
				<div class="input-container">
					<label for="firstname">Email*</label>
					<input
						type="text"
						placeholder="johndoe@gmail.com"
						:class="{ 'not-valid': !email.init && !email.isValid }"
						v-model="email.val"
						@change="validateInput(email)"
					/>
				</div>
				<div class="input-container">
					<label for="firstname">Phone</label>
					<input
						type="text"
						placeholder="555-555-5555"
						:class="{ 'not-valid': !phone.init && !phone.isValid }"
						v-model="phone.val"
						@change="validateInput(phone)"
					/>
				</div>
				<div class="input-container">
					<label for="contactmethod">Preferred Contact Method</label>
					<select v-model="contactMethod.val">
						<option value="email">Email</option>
						<option value="phonecall">Phone Call</option>
						<option value="text">Text</option>
					</select>
				</div>
			</div>
			<div class="message-field">
				<div class="textarea-container">
					<label for="message">Message*</label>
					<textarea
						type="text"
						placeholder="Need a quote? Questions? Let us know how we can help."
						:class="{ 'not-valid': !message.init && !message.isValid }"
						v-model="message.val"
						@change="validateInput(message)"
					></textarea>
				</div>
			</div>
			<!-- Captcha -->
			<div id="captchaCont">
				<div class="captcha-img-cont" @click="setCaptcha">
					<img :src="captchaImg" class="captcha-img" />
				</div>
				<div class="captcha-input-container">
					<div class="title-cont">No bots allowed!*</div>
					<input
						type="text"
						placeholder="12345"
						v-model="captcha.val"
						@keyup="validateInput(captcha)"
						:class="{ 'not-valid': !captcha.init && !captcha.isValid }"
					/>
				</div>
			</div>
			<div class="submit-field">
				<button
					type="submit"
					value="Send"
					class="submit-button"
					:class="{ 'submit-button-off': !formReady }"
				>
					<LargeLoader v-if="isLoading" />
					<span v-else class="submit-text">Send</span>
				</button>
			</div>
		</form>
		<div v-else class="thank-you-cont">
			<h1 class="thank-you-headline">Thank you, {{ firstname.val }}!</h1>
			<h3>We'll be in touch soon.</h3>
		</div>
	</div>
</template>

<script>
// Component Imports
import LargeLoader from '@/components/reusables/LargeLoader.vue';

// Dependency Imports
import { ref, onBeforeMount, reactive, toRefs } from 'vue';
import { api } from '@/plugins/wire-config.js';

export default {
	name: 'ContactForm',
	components: {
		LargeLoader,
	},

	setup() {
		// -=-=-=-=- Component Data/State -=-=-=-=-
		const isLoading = ref(false);
		const formReady = ref(false);
		const formAccepted = ref(false);

		const expressions = {
			name: /^[a-zA-Z']+$/,
			multipleWords: /^[a-zA-Z0-9.,&:!'"()?#@*+\- ]+$/,
			email: /^([a-zA-Z0-9-.])+@([a-zA-Z0-9-])+\.([a-zA-Z]{2,}$)/,
			phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
			message: /^[a-zA-Z0-9 !$'";,.@$?#&()\-_\n\t\r]{1,1200}$/,
			captcha: /^[0-9]{5,5}$/,
		};

		const formData = reactive({
			firstname: {
				val: null,
				regex: expressions.name,
				required: true,
				isValid: null,
				init: true,
			},
			lastname: {
				val: null,
				regex: expressions.name,
				required: true,
				isValid: null,
				init: true,
			},
			company: {
				val: null,
				regex: expressions.multipleWords,
				required: false,
				isValid: true,
				init: true,
			},
			email: {
				val: null,
				regex: expressions.email,
				required: true,
				isValid: null,
				init: true,
			},
			phone: {
				val: null,
				regex: expressions.phone,
				required: false,
				isValid: true,
				init: true,
			},
			contactMethod: {
				val: 'email',
				regex: expressions.multipleWords,
				required: true,
				isValid: true,
			},
			message: {
				val: null,
				regex: expressions.message,
				required: true,
				isValid: null,
				init: true,
			},
			captcha: {
				val: null,
				regex: expressions.captcha,
				required: true,
				isValid: false,
				init: true,
			},
		});

		// -=-=-=-=-=-=- Methods -=-=-=-=-=-=-=-
		// Form submission
		async function submitMessage() {
			// Prevents people from spamming submit while waiting for a response.
			if (isLoading.value) {
				console.log('Patience is a virtue. Stop spamming!');
				return;
			}

			// Check form validity and dump values in an object to be sent.
			if (!formReady.value) {
				alert(
					'Please ensure all required fields are properly filled and formatted!'
				);
				return;
			}
			let filledFormData = {};
			for (let input in formData) {
				filledFormData[input] = formData[input].val;
			}
			isLoading.value = true;

			// POST to the server
			let submission;
			try {
				submission = await api.post('/contactform/', filledFormData, (err) => {
					throw err;
				});
			} catch (err) {
				if (err == 401) {
					isLoading.value = false;
					formAccepted.value = false;
					setCaptcha();
					return alert('Captcha is incorrect. Please try again.');
				}
			}

			if (!submission._ok) {
				alert(submission._message);
				return;
			}

			isLoading.value = false;
			formAccepted.value = true;
		}

		// Captcha
		let captchaImg = ref('');

		function setCaptcha() {
			let timestamp = new Date().getTime();
			captchaImg.value = `https://api.bitfluent.us/captcha?${timestamp}`;
			formData.captcha.val = null;
		}

		function updateCaptchaSolution(data) {
			formData.captcha.isValid = data.isValid;
			formData.captcha.val = data.val;
			setFormStatus();
		}

		// Relies on the existence of properties in formData
		function validateInput(inputId) {
			let test = inputId.regex.test(inputId.val);

			if ('init' in inputId) {
				inputId.init = false;
			}

			if (inputId.val == '' && inputId.required) {
				inputId.isValid = false;
				inputId.init = false;
				setFormStatus();
				return;
			}
			if (inputId.val != '' && test == false) {
				inputId.isValid = false;
				inputId.init = false;
				setFormStatus();
				return;
			}
			if (inputId.val != '' && test == false) {
				inputId.isValid = false;
				inputId.init = false;
				setFormStatus();
				return;
			}

			inputId.isValid = true;
			inputId.init = false;

			setFormStatus();
		}

		function setFormStatus() {
			for (let input in formData) {
				if (!formData[input].isValid) {
					formReady.value = false;
					return;
				}
			}

			formReady.value = true;
		}

		// -=-=-=-=-=-=- Lifecycle -=-=-=-=-=-=-=-
		onBeforeMount(() => {
			setCaptcha();
		});

		return {
			expressions,
			submitMessage,
			isLoading,
			formAccepted,
			preferredContactOptions: ['Email', 'Phone Call', 'Text'],
			requiredTrue: true,
			requiredFalse: false,
			validateInput,
			formReady,
			updateCaptchaSolution,
			setCaptcha,
			captchaImg,
			...toRefs(formData),
		};
	},
};
</script>

<style scoped>
/* -=-=-=-=- General template -=-=-=-=-  */
#contactContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 110%;
	max-width: 850px;
	min-height: 500px;
	padding: 50px 10px 0px 10px;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient);
	box-shadow: var(--card-shadow);
	color: var(--text-color);
	border: solid green 0px;
}

#formCont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.thank-you-cont {
	width: 100%;
	height: 100%;
	border: soild red 1px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: translateY(-20px);
}

h1 {
	color: rgba(255, 255, 255, 0.92);
	text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 26px;
	margin: 10px -10px 10px -10px;
	border: solid yellow 0px;
	font-size: clamp(8px, 7vw, 36px);
}

.thank-you-headline {
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
}

.text-gradient {
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.small-field {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
	border: solid green 0px;
}

.message-field {
	min-height: 300px;
	width: 100%;
	border: solid blue 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 20px 0px;
}

.submit-field {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* -=-=-=-=- Input Styles -=-=-=-=- */
.input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 20px;
	max-width: 300px;
	width: 100%;
}

label {
	margin: 8px 2px 8px 2px;
}

input {
	border-radius: 12px;
	height: 44px;
	width: 100%;
	border: solid 2px rgba(124, 69, 217, 0);
	padding: 10px 12px 10px 12px;
	transition-duration: 300ms;
	font-size: 18px;
	box-shadow: 0px 4px 10px rgba(190, 190, 190, 0.2);
	background: rgba(255, 255, 255, 1) url('../../assets/alert-icon.svg')
		calc(100% + 30px) center no-repeat;
}

input:focus {
	border-radius: 12px;
	border: solid 2px rgba(160, 75, 237, 1);
	outline: none;
	box-shadow: 0px 4px 18px rgba(150, 150, 150, 0.4);
}

input::placeholder {
	color: rgba(0, 0, 0, 0.3);
}

select {
	border-radius: 12px;
	height: 44px;
	width: 100%;
	border: solid 2px rgba(124, 69, 217, 0);
	padding: 10px 12px 10px 12px;
	transition-duration: 300ms;
	font-size: 18px;
	box-shadow: 0px 4px 10px rgba(190, 190, 190, 0.2);
	background: rgba(255, 255, 255, 1);
}

select:focus {
	border-radius: 12px;
	border: solid 2px rgba(160, 75, 237, 1);
	outline: none;
	box-shadow: 0px 4px 18px rgba(150, 150, 150, 0.4);
}

textarea {
	border-radius: 12px;
	height: 44px;
	width: 100%;
	height: 100%;
	border: solid 2px rgba(124, 69, 217, 0);
	padding: 10px 12px 10px 12px;
	transition-duration: 300ms;
	font-size: 18px;
	box-shadow: 0px 4px 10px rgba(190, 190, 190, 0.2);
	background: rgba(255, 255, 255, 1) url('../../assets/alert-icon.svg')
		calc(100% + 30px) center no-repeat;
	resize: none;
	flex-grow: 1;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

textarea:focus {
	border-radius: 12px;
	border: solid 2px rgba(160, 75, 237, 1);
	outline: none;
	box-shadow: 0px 4px 18px rgba(150, 150, 150, 0.4);
}

textarea::placeholder {
	color: rgba(0, 0, 0, 0.3);
}

#captchaCont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	border: solid white 0px;
	margin: 0px 0px 18px 0px;
}

.captcha-img-cont {
	border: solid yellow 0px;
	margin: 20px;
}

.captcha-img {
	border: solid 0px rgba(160, 75, 237, 1);
	width: 200px;
}

.captcha-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 20px;
	max-width: 200px;
	width: 100%;
}

.title-cont {
	margin: 0px 2px 8px 2px;
}

.not-valid {
	background: rgba(255, 221, 221, 1) url('../../assets/alert-icon.svg')
		calc(100% - 6px) center no-repeat;
	background-size: 22px;
}

.textarea-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 20px;
	max-width: 640px;
	width: 100%;
	height: 100%;
	min-height: 300px;
	border: solid yellow 0px;
}

.submit-button {
	width: 280px;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 20px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
	border: none;
}

.submit-button:focus {
	border: solid white 2px;
	outline: none;
}

.submit-button:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.submit-button:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.submit-button-off {
	opacity: 0.2;
	transform: translateY(4px);
}
</style>
